import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharableService } from '../../sharable.service';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-user-creation',
  templateUrl: './user-creation.component.html',
  styleUrls: ['./user-creation.component.css']
})
export class UserCreationComponent implements OnInit {

  constructor(private dataService: DataService, private authService: AuthService, private router: Router, public toastr: ToastrManager, private sharableService: SharableService, private ngxUi: NgxUiLoaderService) { }

  errType = '';
  numb = 1;

  email = "";
  pass = "";
  conf_pass = "";
  qua = 1;

  // form = new FormGroup({
  //   first: new FormControl('danishalalam@globussoft.in'),
  //   last: new FormControl('Drew'),
  // });
  ngOnInit() {
  }

  Toast = SharableService.Toast;

  onRegister(f: NgForm) {
    this.ngxUi.start();

    // console.log(f.value);
    //  return true;      
    this.dataService.createUser(f.value.email, f.value.password, f.value.password_confirmation, f.value.quantity, f.value.status)
      .subscribe(
        (data: any) => {
          if (data.code == 200) {
            this.Toast.fire({
              icon: 'success',
              title: data.message
            })
            let currentUrl = this.router.url;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([currentUrl]);
          }
          else {
            this.Toast.fire({
              icon: 'error',
              title: data.message
            })
          }
        },
        error => {
          if (error.error.errors.email) {
            this.errType = error.error.errors.email[0];
          }
          else if (error.error.errors.name) {
            this.errType = error.error.errors.name[0];
          }
          else if (error.error.errors.username) {
            this.errType = error.error.errors.username[0];
          }
          else if (error.error.errors.password) {
            this.errType = error.error.errors.password[0];
          }
          this.Toast.fire({
            icon: 'error',
            title: this.errType
          })
        }
      );

    this.ngxUi.stop();
  }

  doTheBack() {
    window.history.back();
  }

}

import { Component, OnInit } from '@angular/core';
import { SharableService } from '../sharable.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.css']
})
export class BottomNavComponent implements OnInit {

  constructor(private share: SharableService,private router:Router) { }

  ngOnInit() {
  }

  scroll(scrollValue) {
    this.share.scrollValue=scrollValue;
    this.router.navigate(['/opal-characteristics']);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {
    id: string;
  constructor(private route : ActivatedRoute,private authService: AuthService) { }

  ngOnInit() {
   this.id = this.route.snapshot.paramMap.get('id');
   
   this.authService.validate(this.id);
  }

}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharableService } from '../sharable.service';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  checkLoginStatus: boolean;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  errType = '';
  isLoading: Subject<boolean> = this.sharableService.isLoading;
  model = '';


  constructor(private dataService: DataService,private authService: AuthService, private router: Router, public toastr: ToastrManager, private sharableService: SharableService, private ngxUi: NgxUiLoaderService) { }

  content: any[][]=[[],[]];

  showEditButton=false;

  ngOnInit() {

    window.scroll(0, 0);
    /* Added by alok */
    const token = localStorage.getItem('access_token');
    if (token) {
      this.checkLoginStatus = true;
    }
    else {
      this.checkLoginStatus = false;
    }
    /*end alok*/
     this.dataService.getPageContent('home').subscribe((data: any) => {
      if(data.code==200){
        this.content=JSON.parse(data.content);
      }
     });

    //  this.showEditButton=this.sharableService.getGlobalVar();
    if (localStorage.getItem('user')) {
      this.dataService.checkEditStatus().subscribe((data: any) => {
        if (data.code == 200) {
          if(data.data==1){
          this.showEditButton=true
          }
        }
      });
    }
  }

  modules=SharableService.Module;
  Toast=SharableService.Toast;

  updatContent:any;
  contentId:any;
  index:any;

  edit(contentId,index){
   this.contentId=contentId;
   this.index=index;
   this.updatContent=this.content[index]['content'];
  }
  saveText(){
    this.updatContent=this.sharableService.removeExtraLineAtEnd(this.updatContent);
    this.content[this.index]['content']=this.updatContent;
    this.dataService.setPageContent(this.contentId,this.updatContent).subscribe((data: any) => {
      if(data.code==200){
        this.Toast.fire({
          icon: 'success',
          title: 'Changes has been saved !'
        })       
      }
      else{
        this.Toast.fire({
          icon: 'error',
          title: 'Something went wrong !'
        }) 
      }
     });
  }

  errors: object;

  loggedIn = '';

  onSubmitt(lg: NgForm) {
    this.authService.loginUser(lg.value.email, lg.value.password, 'true');
    this.authService.isUserLoggedIn.subscribe((val: any) => {
      this.loggedIn = val;
      this.router.navigateByUrl('/dashboard');
    }
    );
  }

  onForgot(fgt: NgForm) {
    this.authService.forgot(fgt.value.email)
      .subscribe(
        data => this.toastr.successToastr('data.message'),
        error => {
          this.toastr.errorToastr('error.error.message')
        }
      );
  }

  checkBoxFlag = false;
  checkCheckBox() {
    this.checkBoxFlag = !this.checkBoxFlag;
  }

  onRegister(f: NgForm) {
    this.ngxUi.start();
    if (f.value.chk) {
      // console.log(f.value.chk,f.value.name,f.value.username,f.value.email,f.value.tel,f.value.password,f.value.password_confirmation);
      this.authService.register(f.value.email, f.value.password, f.value.password_confirmation)
        .subscribe(
          (data: any) => {
            this.Toast.fire({
              icon: 'success',
              title: data.message
            })
          },
          error => {
            console.log(error)

            if (error.error.errors.email) {
              this.errType = error.error.errors.email[0];
            }
            else if (error.error.errors.name) {
              this.errType = error.error.errors.name[0];
            }
            else if (error.error.errors.username) {
              this.errType = error.error.errors.username[0];
            }
            else if (error.error.errors.password) {
              this.errType = error.error.errors.password[0];
            }
            this.Toast.fire({
              icon: 'error',
              title: this.errType
            })
          }
        );
    }
    this.ngxUi.stop();
  }
}

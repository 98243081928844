import { Component, OnInit} from '@angular/core';
import { AuthService } from '../auth.service';
import { SharableService } from "../sharable.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  isLoggedIn=false;
  constructor(private authService: AuthService, private sharable:SharableService,private cookies:CookieService) { }

  ngOnInit() {

    window.scroll(0,0);
    if(localStorage.getItem('access_token')){
         this.isLoggedIn=true;
       }
}

name="Opal Princing";
mode="instant";

  sendAmount(amount:any,qty:any,pricing_type){
    if(this.isLoggedIn==false)
    {
      localStorage.setItem('checkout', String(true));
    }
    if(qty==0){
      qty='unlimited';
      this.name="Monthly Subcription";
      this.mode='recurring';
    }
    if(qty==1){
      qty='unlimited';
      this.name="Anual Subcription";
      this.mode='recurring';
    }
    this.cookies.set('mode',this.mode);
    this.cookies.set('amount',amount);
    this.cookies.set('qty',qty);
    this.cookies.set('name',this.name);
    this.cookies.set('pricing_id',pricing_type);
    // this.sharable.changeMessage(amount,qty);
  }


}

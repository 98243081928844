import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-land',
  templateUrl: './pricing-land.component.html',
  styleUrls: ['./pricing-land.component.css']
})
export class PricingLandComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

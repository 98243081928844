import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { NgForm } from '@angular/forms';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-create-a-compaign',
  templateUrl: './create-a-compaign.component.html',
  styleUrls: ['./create-a-compaign.component.css']
})
export class CreateACompaignComponent implements OnInit {

  constructor(private ngxLoader: NgxUiLoaderService, private dataS: DataService, private router: Router) { }

  feature = 'Feature 1'

  selectedFeatures: any = [];

  image_path_arr: any = [];

  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  data: any[][];
  finalData: any[];

  fileData = null;

  validateFlag = false;
  checked: any;

  ngOnInit() {
  }

  black = [512, 513, 514, 515, 516, 517, 518, 519, 576, 577, 578, 579, 580, 581, 582, 583];
  dark = [520, 521, 522, 523, 524, 584, 585, 586, 587, 588];
  light = [525, 526, 527, 528, 529, 530, 531, 532, 589, 590, 591, 592, 593, 594, 595, 596];

  valid_csv_data= [
    ['Natural Black Opal Type 1', 'Natural Dark Opal Type 1', 'Natural Light Opal Type 1', 'Natural Boulder Opal Type 2'],
    ['Black N1 (1)','Black N1 (2)','Black N2 (3)','Black N2 (4)','Black N3 (5)','Black N3 (6)','Black N4 (7)','Black N4 (8)','Dark N5 (9)','Dark N5 (10)','Dark N6 (11)','Dark N6 (12)','Dark N6 (13)','Light N7 (14)','Light N7 (15)','Light N8 (16)','Light N8 (17)','Light N9 (18)','Light N9 (19)','Light N9 (20)','Light N9 (21)'],
    ['Vivid','Briliant','Very Bright','Bright','Slightly Bright','Average','Slightly Dull','Dull','Very Dull','Faint'],
    ['Transparent','Translucent','Opaque'],
    ['Red','Red-Orange','Red-Green','Red - Blue','Red - Multi','Multicolour','Orange','Orange - Red','Orange - Green','Orange - Blue','Orange - Multi','Yellow','Yellow - Orange','Yellow - Green','Yellow-Blue','Yellow-Multi','Green','Green-Red','Green-Orange','Green-Blue','Green-Multi','Blue','Blue-Red','Blue-Orange','Blue-Green','Blue-Multi','Multicolour'],
    ['Deep Rich Tones','Rich Tones','Clear Strong Tones','Normal Tones','Slightly Pale Tones','Pale Tones','Very Pale Tones','Washed Out Tones','Faint Tones'],
    ['Free Shape','Baroque Shape','Oval Shape','Very Long Oval','Heart, Marquise, Triangle','square, Round, Oblong'],
    ['Too High Cabochon','High Cabochon','Medium Cabochon','Low Cabochon','Flat Face','Baroque Face'],
    ['Lightning Ridge','South Australia','Queensland','Andamooka','Unknown'],
    ['Mixed Pattern','Regular Pattern','Blocky Pattern','Broad Blocky Pattern','Broad Flash Pattern','Named Pattern','Rare Named Pattern','Genuine Harlequin Pattern','Pinfire Pattern','Mossy Pattern','Sheen Pattern','Picture Stone Pattern'],
    ['Good from All Angles','Weak or Blank 1 Angle','Weak or Blank 2 Angles','Weak or Blank 3 Angles'],
    ['Over 80%','70-79%','60-69%','50-59%','40-49%','30-39%','20-29%','Under 20%'],
    ['No Inclusions','Loupe Visible Only','1% of Face','5% of Face','10% of Face','20% of Face','30% of Face','40% of Face','50% of Face','70% of Face','80% of Face','90% of Face'],
    ['normal retail price','auction reserve','non forced sale','wholesale','private sale','second hand','divorce property settlement','deceased estate','forced sale'],
    ['AUD','USD']
  ];

  arrayBuffer: any;
  target: DataTransfer;
  onFileSelected(evt) {
    /* wire up file reader */
    this.target = <DataTransfer>(evt.target);
    var fileExtension = this.target.files[0].name.split('.').pop();
    if (this.target.files.length !== 1) throw new Error('Cannot use multiple files');
    if (fileExtension != "xlsx" && fileExtension != "csv") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Only xlsx and csv file is applicable'
      })
    }
    else {
      this.validateFlag = true;
    }
    this.checked = false;
  }

  validateCsvData(e) {
    if (!this.validateFlag) {
    }
    else {
      if (e.target.checked) {
        this.selectedFeatures = [];
        this.ngxLoader.start();
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /* read workbook */
          // console.log(e.target.result);
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

          /* grab first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /* save data */
          this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
          console.log(this.data);
          var numberOfRows = this.data.length;
          for (var i = 1; i < numberOfRows; i++) {
            for (var j = 0; j < 18; j++) {
              if (this.data[i][j] == undefined ) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Please Check Your Value in [' + (i) + '] row and [' +((++j + 9).toString(36).toUpperCase())+'] column.'
                });
                this.checked = false;
                this.ngxLoader.stop();
                return true;
              }
              if(j<13){
                if (!this.valid_csv_data[j].includes(this.data[i][j]) ) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please Check Your Value in [' + (i) + '] row and [' +((++j + 9).toString(36).toUpperCase())+'] column.'
                  });
                  this.checked = false;
                  this.ngxLoader.stop();
                  return true;
                }
              }
             
            }
           
            switch (this.data[i][0]) {
              case 'Natural Black Opal Type 1':
                var variety = 16;
                break;
              case 'Natural Dark Opal Type 1':
                var variety = 17;
                break;
              case 'Natural Light Opal Type 1':
                var variety = 18;
                break;
              case 'Natural Boulder Opal Type 2':
                var variety = 19;
                break;
            }

            switch (this.data[i][1]) {
              case 'Black N1 (1)':
                var body_tone = 512;
                break;
              case 'Black N1 (2)':
                var body_tone = 513;
                break;
              case 'Black N2 (3)':
                var body_tone = 514;
                break;
              case 'Black N2 (4)':
                var body_tone = 515;
                break;
              case 'Black N3 (5)':
                var body_tone = 516;
                break;
              case 'Black N3 (6)':
                var body_tone = 517;
                break;
              case 'Black N4 (7)':
                var body_tone = 518;
                break;
              case 'Black N4 (8)':
                var body_tone = 519;
                break;
              case 'Dark N5 (9)':
                var body_tone = 520;
                break;
              case 'Dark N5 (10)':
                var body_tone = 521;
                break;
              case 'Dark N6 (11)':
                var body_tone = 522;
                break;
              case 'Dark N6 (12)':
                var body_tone = 523;
                break;
              case 'Dark N6 (13)':
                var body_tone = 524;
                break;
              case 'Light N7 (14)':
                var body_tone = 525;
                break;
              case 'Light N7 (15)':
                var body_tone = 526;
                break;
              case 'Light N8 (16)':
                var body_tone = 527;
                break;
              case 'Light N8 (17)':
                var body_tone = 528
                break;
              case 'Light N9 (18)':
                var body_tone = 529;
                break;
              case 'Light N9 (19)':
                var body_tone = 530;
                break;
              case 'Light N9 (20)':
                var body_tone = 531;
                break;
              case 'Light N9 (21)':
                var body_tone = 532;
                break;
            }

            switch (this.data[i][2]) {
              case 'Vivid':
                var brightness = 48;
                break;
              case 'Briliant':
                var brightness = 49;
                break;
              case 'Very Bright':
                var brightness = 50;
                break;
              case 'Bright':
                var brightness = 51;
                break;
              case 'Slightly Bright':
                var brightness = 52;
                break;
              case 'Average':
                var brightness = 53;
                break;
              case 'Slightly Dull':
                var brightness = 54;
                break;
              case 'Dull':
                var brightness = 55;
                break;
              case 'Very Dull':
                var brightness = 56;
                break;
              case 'Faint':
                var brightness = 57;
                break;
            }

            switch (this.data[i][3]) {
              case 'Transparent':
                var transparency = 64;
                break;
              case 'Translucent':
                var transparency = 65;
                break;
              case 'Opaque':
                var transparency = 66;
                break;
            }

            switch (this.data[i][4]) {
              case 'Red':
                var colour_combination = 1280;
                break;
              case 'Red-Orange':
                var colour_combination = 1281;
                break;
              case 'Red-Green':
                var colour_combination = 1282;
                break;
              case 'Red - Blue':
                var colour_combination = 1283;
                break;
              case 'Red - Multi':
                var colour_combination = 1284;
                break;
              case 'Orange':
                var colour_combination = 1285;
                break;
              case 'Orange - Red':
                var colour_combination = 1286;
                break;
              case 'Orange - Green':
                var colour_combination = 1287;
                break;
              case 'Orange - Blue':
                var colour_combination = 1288;
                break;
              case 'Orange - Multi':
                var colour_combination = 1289;
                break;
              case 'Yellow':
                var colour_combination = 1290;
                break;
              case 'Yellow - Orange':
                var colour_combination = 1291;
                break;
              case 'Yellow - Green':
                var colour_combination = 1292;
                break;
              case 'Yellow-Blue':
                var colour_combination = 1293;
                break;
              case 'Yellow-Multi':
                var colour_combination = 1294;
                break;
              case 'Green':
                var colour_combination = 1295;
                break;
              case 'Green-Red':
                var colour_combination = 1296;
                break;
              case 'Green-Orange':
                var colour_combination = 1297;
                break;
              case 'Green-Blue':
                var colour_combination = 1298;
                break;
              case 'Green-Multi':
                var colour_combination = 1299;
                break;
              case 'Blue':
                var colour_combination = 1300;
                break;
              case 'Blue-Red':
                var colour_combination = 1301;
                break;
              case 'Blue-Orange':
                var colour_combination = 1302;
                break;
              case 'Blue-Green':
                var colour_combination = 1303;
                break;
              case 'Blue-Multi':
                var colour_combination = 1304;
                break;
              case 'Multicolour':
                var colour_combination = 1305;
                break;
            }



            switch (this.data[i][5]) {
              case 'Deep Rich Tones':
                var tone_hue = 96;
                break;
              case 'Rich Tones':
                var tone_hue = 97;
                break;
              case 'Clear Strong Tones':
                var tone_hue = 98;
                break;
              case 'Normal Tones':
                var tone_hue = 99;
                break;
              case 'Slightly Pale Tones':
                var tone_hue = 100;
                break;
              case 'Pale Tones':
                var tone_hue = 101;
                break;
              case 'Very Pale Tones':
                var tone_hue = 102;
                break;
              case 'Washed Out Tones':
                var tone_hue = 103;
                break;
              case 'Faint Tones':
                var tone_hue = 104;
                break;
            }

            switch (this.data[i][6]) {
              case 'Free Shape':
                var outline_shape = 112;
                break;
              case 'Baroque Shape':
                var outline_shape = 113;
                break;
              case 'Oval Shape':
                var outline_shape = 114;
                break;
              case 'Very Long Oval':
                var outline_shape = 115;
                break;
              case 'Heart, Marquise, Triangle':
                var outline_shape = 116;
                break;
              case 'square, Round, Oblong':
                var outline_shape = 117;
                break;
            }

            switch (this.data[i][7]) {
              case 'Too High Cabochon':
                var profile = 128;
                break;
              case 'High Cabochon':
                var profile = 129;
                break;
              case 'Medium Cabochon':
                var profile = 130;
                break;
              case 'Low Cabochon':
                var profile = 131;
                break;
              case 'Flat Face':
                var profile = 132;
                break;
              case 'Baroque Face':
                var profile = 133;
                break;
            }

            switch (this.data[i][8]) {
              case 'Lightning Ridge':
                var field = 144;
                break;
              case 'South Australia':
                var field = 145;
                break;
              case 'Queensland':
                var field = 146;
                break;
              case 'Andamooka':
                var field = 147;
                break;
              case 'Unknown':
                var field = 148;
                break;
            }

            switch (this.data[i][9]) {
              case 'Mixed Pattern':
                var color_patt = 160;
                break;
              case 'Regular Pattern':
                var color_patt = 161;
                break;
              case 'Blocky Pattern':
                var color_patt = 162;
                break;
              case 'Broad Blocky Pattern':
                var color_patt = 163;
                break;
              case 'Broad Flash Pattern':
                var color_patt = 164;
                break;
              case 'Named Pattern':
                var color_patt = 165;
                break;
              case 'Rare Named Pattern':
                var color_patt = 166;
                break;
              case 'Genuine Harlequin Pattern':
                var color_patt = 167;
                break;
              case 'Pinfire Pattern':
                var color_patt = 168;
                break;
              case 'Mossy Pattern':
                var color_patt = 169;
                break;
              case 'Sheen Pattern':
                var color_patt = 170;
                break;
              case 'Picture Stone Pattern':
                var color_patt = 171;
                break;
            }

            switch (this.data[i][10]) {
              case 'Good from All Angles':
                var direction = 176;
                break;
              case 'Weak or Blank 1 Angle':
                var direction = 177;
                break;
              case 'Weak or Blank 2 Angles':
                var direction = 178;
                break;
              case 'Weak or Blank 3 Angles':
                var direction = 179;
                break;
            }

            switch (this.data[i][11]) {
              case 'Over 80%':
                var colour_dist = 192;
                break;
              case '70-79%':
                var colour_dist = 193;
                break;
              case '60-69%':
                var colour_dist = 194;
                break;
              case '50-59%':
                var colour_dist = 195;
                break;
              case '40-49%':
                var colour_dist = 196;
                break;
              case '30-39%':
                var colour_dist = 197;
                break;
              case '20-29%':
                var colour_dist = 198;
                break;
              case 'Under 20%':
                var colour_dist = 199;
                break;
            }

            switch (this.data[i][12]) {
              case 'No Inclusions':
                var face_inc = 208;
                break;
              case 'Loupe Visible Only':
                var face_inc = 209;
                break;
              case '1% of Face':
                var face_inc = 210;
                break;
              case '5% of Face':
                var face_inc = 211;
                break;
              case '10% of Face':
                var face_inc = 212;
                break;
              case '20% of Face':
                var face_inc = 213;
                break;
              case '30% of Face':
                var face_inc = 214;
                break;
              case '40% of Face':
                var face_inc = 215;
                break;
              case '40% of Face':
                var face_inc = 216;
                break;
              case '50% of Face':
                var face_inc = 217;
                break;
              case '70% of Face':
                var face_inc = 218;
                break;
              case '80% of Face':
                var face_inc = 219;
                break;
              case '90% of Face':
                var face_inc = 220;
                break;
            }

            switch (this.data[i][14]) {
              case 'normal retail price':
                var markup = 1;
                break;
              case 'auction reserve':
                var markup = 2;
                break;
              case 'non forced sale':
                var markup = 3;
                break;
              case 'wholesale':
                var markup = 4;
                break;
              case 'private sale':
                var markup = 5;
                break;
              case 'second hand':
                var markup = 6;
                break;
              case 'divorce property settlement':
                var markup = 7;
                break;
              case 'deceased estate':
                var markup = 8;
                break;
              case 'forced sale':
                var markup = 9;
                break;
            }

            if (variety == 16) {
              if (!this.black.includes(body_tone)) {
                let n = i;
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Please Check Your Body-Tone Value in [' + (++n) + '] row.'
                });
                this.checked = false;
                this.ngxLoader.stop();
                return false;
              }
            }
            if (variety == 17) {
              if (!this.dark.includes(body_tone)) {
                let n = i;
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Please Check Your Body-Tone Value in [' + (++n) + '] row.'
                });
                this.checked = false;
                this.ngxLoader.stop();
                return false;
              }
            }
            if (variety == 18) {
              if (!this.light.includes(body_tone)) {
                let n = i;
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Please Check Your Body-Tone Value in [' + (++n) + '] row.'
                });
                this.checked = false;
                this.ngxLoader.stop();
                return false;
              }
            }

            this.image_path_arr.push({[0]:this.data[i][20],[1]:this.data[i][21],[2]:this.data[i][22],[3]:this.data[i][23]});

            if (this.data[i][18] && this.data[i][19]) {
              this.selectedFeatures.push({ [0]: variety, [1]: body_tone, [2]: brightness, [3]: transparency, [4]: colour_combination, [5]: tone_hue, [6]: outline_shape, [7]: profile, [8]: field, [9]: color_patt, [10]: direction, [11]: colour_dist, [12]: face_inc, [13]: { [0]: this.data[i][13], [1]: markup, [2]: this.data[i][15], [3]: this.data[i][16] }, [14]: this.data[i][17], [15]: this.data[i][18], [16]: this.data[i][19] });
            }
            else {
              this.selectedFeatures.push({ [0]: variety, [1]: body_tone, [2]: brightness, [3]: transparency, [4]: colour_combination, [5]: tone_hue, [6]: outline_shape, [7]: profile, [8]: field, [9]: color_patt, [10]: direction, [11]: colour_dist, [12]: face_inc, [13]: { [0]: this.data[i][13], [1]: markup, [2]: this.data[i][15], [3]: this.data[i][16] }, [14]: this.data[i][17] });
            }
          }

          this.ngxLoader.stop();
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          Toast.fire({
            icon: 'success',
            title: 'Data Validated successfully'
          })

          // console.log(this.selectedFeatures);
        };
        reader.readAsBinaryString(this.target.files[0]);
      }
    }
  }


  downloadSapmleCSV() {
    let link = document.createElement("a");
    link.download = "SampleBulkCSV";
    link.href = "assets/sampleCSV/SampleBulkCSV.xlsx";
    link.click();
  }

  createCompaign(cpgnForm: NgForm) {
    this.ngxLoader.start();
    this.dataS.createCompaign(this.selectedFeatures,this.image_path_arr,cpgnForm.value.cpgn).subscribe((data: any) => {
      this.ngxLoader.stop();
      if (data.code == 200) {
        Swal.fire({
          title: 'Successful',
          text: data.message,
          icon: 'success',
          allowOutsideClick: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['dashboard/user-certificate']);
          }
        })
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.message
        })
      }
    }, error => {
      this.ngxLoader.stop();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.errors.cpgnName[0],
      })
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';
import { SharableService } from '../sharable.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shopify-payments',
  templateUrl: './shopify-payments.component.html',
  styleUrls: ['./shopify-payments.component.css']
})
export class ShopifyPaymentsComponent implements OnInit {

  addOnData: any;
  descriptionData: any;
  productInfo: any;
  requiredData: any; 
  deliveryservicedata:any;
  constructor(private authService: AuthService,private ngxLoader: NgxUiLoaderService,private router: Router, private data: DataService) { }

  paymentDetailsData: any = [];
  flag=false;
  ngOnInit() {
    this.ngxLoader.start(); 
    this.data.getDetails('shopifyDetails').subscribe((data:any)=>{
      if(data.code==200){
        this.paymentDetailsData = data.data;
        this.flag=true;
        $('#appsidebaroverlay').animate({ scrollTop: 600 }, 0);
      }
     })
     this.ngxLoader.stop(); 
  }

  viewShopifyOrderDetails(pre_order_id) {
      this.data.viewShopifyOrderDetails(pre_order_id).subscribe((data: any) => {
      this.addOnData = data.order_data.addOnData;
      this.descriptionData = data.order_data.descriptionData;
      this.productInfo = data.order_data.productInfo;
      this.requiredData = data.order_data.requiredData;
      this.deliveryservicedata =data.delivery_service.delivery_service?data.delivery_service.delivery_service :"Not availaible Now";
       console.log(this.productInfo.invoice_number); 
       console.log(data.delivery_service.delivery_service);
    });
    
  }

  doTheBack(){
    window.history.back();
  }

}


import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.css']
})
export class TopBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  downloadOpalValuationBook() {
    Swal.fire({
      // title: 'Are you sure?',
      text: "You're about to download the free opal valuation book.",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, i want!'
    }).then((result) => {
      if (result.value) {
        let link = document.createElement("a");
        link.download = "Opal-Valuation";
        link.href = "assets/book/Opal-valuation.zip";
        link.click();
      }
    })
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
// import { Injectable } from '@angular/core';
// import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
// import { Observable } from 'rxjs';
import { AuthGuard } from './Guard/auth.guard';
import { AdminGuard } from './Guard/admin.guard';

import { PricingComponent } from './pricing/pricing.component';
import { PricingLandComponent } from './pricing-land/pricing-land.component';
import { EduactionalVideosComponent } from './dashboard/eduactional-videos/eduactional-videos.component';

import { ServicesComponent } from './services/services.component';
import { OpalInfoComponent } from './opal-info/opal-info.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PhotoLibraryComponent } from './photo-library/photo-library.component';

import { HNavComponent } from './dashboard/h-nav/h-nav.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AccountSettingComponent } from './dashboard/account-setting/account-setting.component';
import { UserDetailsComponent } from './dashboard/user-details/user-details.component';
import { UserCreationComponent } from './dashboard/user-creation/user-creation.component';
import { CreateNewCertificateComponent } from './dashboard/create-new-certificate/create-new-certificate.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { UserCertifacateComponent } from './dashboard/user-certifacate/user-certifacate.component';



import { CaretWeightComponent } from './dashboard/carat-weight/caret-weight.component';

import { PaypalResponseComponent } from './paypal-response/paypal-response.component';

import { BodytoneComponent } from './dashboard/bodytone/bodytone/bodytone.component';
import { BodyBrightnessComponent } from './dashboard/brightness/body-brightness.component';
import { TransparencyComponent } from './dashboard/transparency/transparency.component';
import { ColorCombinationComponent } from './dashboard/colour-combination/color-combination.component';
import { ColorSaturationComponent } from './dashboard/colour-saturation/color-saturation.component';
import { BodyOutlineComponent } from './dashboard/outline-shape/body-outline.component';
import { ProfileShapeComponent } from './dashboard/profile-shape/profile-shape.component';
import { OriginComponent } from './dashboard/origin/origin.component';
import { ColorPatternComponent } from './dashboard/color-pattern/color-pattern/color-pattern.component';
import { FaceColorDirectionalityComponent } from './dashboard/face-color-directionality/face-color-directionality.component';
import { FaceColorPercentageComponent } from './dashboard/colour-distribution/face-color-percentage.component';


import { InclusionPercentageComponent } from './dashboard/inclusion-percentage/inclusion-percentage.component';


import { GeneratePdfComponent } from './dashboard/generate-pdf/generate-pdf.component';
import { ActivateComponent } from './activate/activate.component';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CreateACompaignComponent } from './dashboard/create-a-compaign/create-a-compaign.component';

import { PhotolibraryComponent } from './dashboard/brightness/photolibrary/photolibrary.component';
import { ColourPhotolibraryComponent } from './dashboard/colour-saturation/colour-photolibrary/colour-photolibrary.component';
import { OutlinePhotolibraryComponent } from './dashboard/outline-shape/outline-photolibrary/outline-photolibrary.component';
import { OriginPhotolibraryComponent } from './dashboard/origin/origin-photolibrary/origin-photolibrary.component';
import { CcphotolibraryComponent } from './dashboard/colour-combination/ccphotolibrary/ccphotolibrary.component';

import { VarietyPhotolibraryComponent } from './dashboard/create-new-certificate/variety-photolibrary/variety-photolibrary.component';
import { BodytonePhotolibraryComponent } from './dashboard/bodytone/bodytone-photolibrary/bodytone-photolibrary.component';
import { CpPhotolibraryComponent } from './dashboard/color-pattern/cp-photolibrary/cp-photolibrary.component';
import { DirectionPhotolibraryComponent } from './dashboard/face-color-directionality/direction-photolibrary/direction-photolibrary.component';
import { ColourdisPhotolibraryComponent } from './dashboard/colour-distribution/colourdis-photolibrary/colourdis-photolibrary.component';
import { FacePhotolibraryComponent } from './dashboard/inclusion-percentage/face-photolibrary/face-photolibrary.component';
import { PaymentDetailsComponent } from './dashboard/payment-details/payment-details.component';
//Shopify Details
import { ShopifyPaymentsComponent } from './shopify-payments/shopify-payments.component';
import { AddCouponComponent } from './dashboard/add-coupon/add-coupon.component';
import { CouponDetailsComponent } from './dashboard/coupon-details/coupon-details.component';
import { ImageSectionComponent } from './dashboard/image-section/image-section.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'pricing', component: PricingLandComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'opal-characteristics', component: OpalInfoComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'terms&condition', component: TermsConditionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'photo-library', component: PhotoLibraryComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'account-setting', component: AccountSettingComponent, canActivate: [AuthGuard] },

  { path: 'admin/user-details', component: UserDetailsComponent,  canActivate: [AdminGuard] },
  { path: 'admin/user-creation', component: UserCreationComponent,  canActivate: [AdminGuard] },
  { path: 'admin/payment-details', component: PaymentDetailsComponent, canActivate: [AdminGuard]  },
  //Shopify Details
  { path: 'admin/shopify-details', component: ShopifyPaymentsComponent, canActivate: [AdminGuard] },
  
  { path: 'admin/image-section', component: ImageSectionComponent, canActivate: [AdminGuard]  },
  { path: 'admin/add-coupon', component: AddCouponComponent,  canActivate: [AdminGuard] },
  { path: 'admin/coupon-details', component: CouponDetailsComponent, canActivate: [AdminGuard]  },
  
  { path: 'create-new-certificate', component: CreateNewCertificateComponent, canActivate: [AuthGuard] },
  { path: 'pricing/checkout', component: CheckoutComponent, canActivate: [AuthGuard] },
  
  { path: 'variety/photo-library', component: VarietyPhotolibraryComponent },
  { path: 'body-tone/photo-library', component: BodytonePhotolibraryComponent },

  { path: 'body-tone', component: BodytoneComponent, canActivate: [AuthGuard] },
  { path: 'brightness', component: BodyBrightnessComponent, canActivate: [AuthGuard] },
  { path: 'brightness/photo-library', component: PhotolibraryComponent},
  { path: 'transparency', component: TransparencyComponent, canActivate: [AuthGuard] },
  { path: 'colour-combination', component: ColorCombinationComponent, canActivate: [AuthGuard] },
  { path: 'colour-combination/photo-library', component: CcphotolibraryComponent},
  { path: 'colour-saturation', component: ColorSaturationComponent, canActivate: [AuthGuard] },
  { path: 'colour-saturation/photo-library', component: ColourPhotolibraryComponent},
  { path: 'outline-shape', component: BodyOutlineComponent, canActivate: [AuthGuard] },
  { path: 'outline-shape/photo-library', component: OutlinePhotolibraryComponent},
  { path: 'profile-shape', component: ProfileShapeComponent, canActivate: [AuthGuard] },
  { path: 'origin', component: OriginComponent, canActivate: [AuthGuard] },
  { path: 'origin/photo-library', component: OriginPhotolibraryComponent},
  { path: 'colour-pattern', component: ColorPatternComponent, canActivate: [AuthGuard] },
  { path: 'colour-pattern/photo-library', component: CpPhotolibraryComponent, canActivate: [AuthGuard] },
  { path: 'carat-weight', component: CaretWeightComponent, canActivate: [AuthGuard] },
  { path: 'directionality', component: FaceColorDirectionalityComponent, canActivate: [AuthGuard] },
  { path: 'directionality/photo-library', component: DirectionPhotolibraryComponent, canActivate: [AuthGuard] },
  { path: 'colour-distribution', component: FaceColorPercentageComponent, canActivate: [AuthGuard] },
  { path: 'colour-distribution/photo-library', component: ColourdisPhotolibraryComponent, canActivate: [AuthGuard] },
  { path: 'face-inclusions', component: InclusionPercentageComponent, canActivate: [AuthGuard] },
  { path: 'face-inclusions/photo-library', component: FacePhotolibraryComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/user-certificate', component: UserCertifacateComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/generate-pdf', component: GeneratePdfComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/educational-videos', component: EduactionalVideosComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/create-a-compaign', component: CreateACompaignComponent },
  { path: 'paypal-response', component: PaypalResponseComponent },
  { path: 'activate/:id', component: ActivateComponent },
  { path: 'forgot-password/:token', component: ForgotPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

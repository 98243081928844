import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../data.service';
import { SharableService } from '../../../sharable.service';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-outline-photolibrary',
  templateUrl: './outline-photolibrary.component.html',
  styleUrls: ['./outline-photolibrary.component.css']
})
export class OutlinePhotolibraryComponent implements OnInit {

  constructor(private route: ActivatedRoute, private data: DataService) { }

  outlineShapeValue: string;

  imagePath: any = [];
  baseUrl = this.data.baseUrl;
  showEditButton = false;

  ngOnInit() {
    var data = this.route.snapshot.paramMap.get('title')
    this.outlineShapeValue = data;

    this.data.checkEditStatus().subscribe((data: any) => {
      if (data.code == 200) {
        if(data.data==1){
        this.showEditButton=true
        }
      }
    });

    this.data.getGalleryImages(this.outlineShapeValue).subscribe((data: any) => {
      if (data.code == 200) {
        this.imagePath = data.data;
      }
    });
  }

  fileData: any;
  Toast = SharableService.Toast;
  getFile(event) {
    this.fileData = <File>event.target.files[0];
  }
  uploadGallImg(){
    this.data.uploadGallImg(this.fileData,this.outlineShapeValue).subscribe((data: any) => {
      if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: data.message
        })
        this.data.getGalleryImages(this.outlineShapeValue).subscribe((data: any) => {
          if (data.code == 200) {
            this.imagePath = data.data;
          }
        });
      }
      else if (data.code == 406) {
        this.Toast.fire({
          icon: 'error',
          title: data.message
        })
      }
    });
  }

  removeImage(type, value) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to remove!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if (result.value) {
        console.log(type, value);
       this.data.removeGalleryImg(type, value).subscribe((data:any)=>{
        if (data.code == 200) {
          this.Toast.fire({
            icon: 'success',
            title: data.message
          })
          this.data.getGalleryImages(this.outlineShapeValue).subscribe((data: any) => {
            if (data.code == 200) {
              this.imagePath = data.data;
            }
          });
        }
       });
      }
    })
  }

  doTheBack() {
    window.history.back();
  }
}

import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { DataService } from '../../data.service';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-h-nav',
  templateUrl: './h-nav.component.html',
  styleUrls: ['./h-nav.component.css']
})
export class HNavComponent implements OnInit {

  sideNav = false;
  treeOpen = false;
  mobile = false;

  constructor(private renderer: Renderer2, private authService: AuthService, private router: Router, private data: DataService) { }

  ngOnInit() {

    if (window.screen.width <= 820) { // 768px portrait
      this.mobile = true;
    }
    window.onresize = () => this.mobile = window.innerWidth <= 820;
    
    this.authService.validateToken().subscribe((data:any)=>{
      if(data.code!=200)
      {
               Swal.fire({
               title: 'Your Session Has Expired !',
               text: "You have to do login again.",
               icon: 'error',
               allowOutsideClick: false,
               confirmButtonColor: '#3085d6',
               confirmButtonText: 'Ok'
             }).then((result) => {
               if (result.value) {
                 localStorage.clear();
                this.router.navigate(['/']);
               }
             })
      }
    });
  }


  status: boolean = false;
  onTree(event) {
    this.status = !this.status;
  }

  onLogout() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to logout!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!'
    }).then((result) => {
      if (result.value) {
        this.authService.logoutUser();
      }
    })
  }

  onClick(next) {
    this.router.navigate([next]);
  }
}

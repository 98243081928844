import { Component, OnInit } from '@angular/core';
import { SharableService } from "../sharable.service";
import { DataService } from "../data.service";
import { CookieService } from "ngx-cookie-service";
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  constructor(private sharable: SharableService, private data: DataService, private cookies: CookieService,private ngxLoader: NgxUiLoaderService) { }

  check = "checked";
  name: any;
  amount: any;
  finalAmount: any;
  qty: any;
  mode: any;
  pricing_id: any;
  couponNots = false;
  msg = '';
  notiClass = 'text-danger';
  coupon_id=null;
  ngOnInit() {
    this.amount = this.cookies.get('amount');
    this.finalAmount = this.cookies.get('amount');
    this.qty = this.cookies.get('qty');
    this.name = this.cookies.get('name');
    this.mode = this.cookies.get('mode');
    this.pricing_id = this.cookies.get('pricing_id');
  }

  verifyCoupon(cc: NgForm) {
    this.ngxLoader.start();
    this.data.verifyCoupon(cc.value.coupon_code,this.pricing_id).subscribe((data: any) => {
      this.couponNots = true;
      if (data.code === 200) {
        this.finalAmount = data.discountAmount;
        this.msg = data.message;
        this.notiClass = 'text-success';
        this.coupon_id = data.coupon_id
      }
      if (data.code === 400) {
        this.msg = data.message;
        this.notiClass = 'text-danger';
        this.finalAmount = this.cookies.get('amount');
        this.coupon_id=null;
      }
      this.ngxLoader.stop();
    })
  }

  onPay(finalAmount) {
      this.data.onPay(finalAmount, this.mode,this.pricing_id,this.coupon_id);
  }

}

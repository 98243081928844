import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { SharableService } from '../../sharable.service';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-eduactional-videos',
  templateUrl: './eduactional-videos.component.html',
  styleUrls: ['./eduactional-videos.component.css']
})
export class EduactionalVideosComponent implements OnInit {

  constructor(private dataService: DataService, private sharableService: SharableService) { }

  content: any[][] = [[], []];
  showEditButton = false;



  ngOnInit() {

    this.dataService.getPageContent('educational-videos').subscribe((data: any) => {
      if (data.code == 200) {
        this.content = JSON.parse(data.content);
      }
    });
    
    this.dataService.checkEditStatus().subscribe((data: any) => {
      if (data.code == 200) {
        if(data.data==1){
        this.showEditButton=true
        }
      }
    });
  }

  modules = SharableService.Module;
  Toast = SharableService.Toast;

  updatContent: any;
  contentId: any;
  index: any;

  edit(contentId, index) {
    this.contentId = contentId;
    this.index = index;
    this.updatContent = this.content[index]['content'];
  }
  saveText() {
    this.updatContent=this.sharableService.removeExtraLineAtEnd(this.updatContent);
    this.content[this.index]['content'] = this.updatContent;
    this.dataService.setPageContent(this.contentId, this.updatContent).subscribe((data: any) => {
      if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: 'Changes has been saved !'
        })
      }
    });
  }

  doTheBack() {
    window.history.back();
  }

  enableEdit(){
    this.showEditButton = !this.showEditButton;
  }
}

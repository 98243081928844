import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { SharableService } from '../../sharable.service';
import Swal from "sweetalert2";
declare let $: any;

@Component({
  selector: 'app-color-combination',
  templateUrl: './color-combination.component.html',
  styleUrls: ['./color-combination.component.css']
})
export class ColorCombinationComponent implements OnInit {

  constructor(private data: DataService, private router: Router, private authService: AuthService, private sharableService: SharableService) { }

  content: any[][] = [[], []];
  showEditButton = false;
  name: any;
  options: any = [];

  setValue = null;
  next: string;

  fullName: string;
  imagePath: string;
  dashboardImagePath = this.data.baseUrl + 'assets/dashboard_images/';

  ngOnInit() {
    $('#appsidebaroverlay').animate({ scrollTop: 275 }, 0);

    const user = localStorage.getItem('user');
    this.fullName = JSON.parse(user).name;
    this.imagePath = this.data.imgUrl + JSON.parse(user).image_path;

    this.data.getVariety('colour-combination').subscribe((data: any) => {
      if (data.code == 400 || data.code == 401 || data.code == 402 || data.code == 204) {
        if (data.code == 204) {
          Swal.fire({
            title: 'Thanks...',
            html: data.status,
            icon: 'info',
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Continue'
          }).then((result) => {
            this.router.navigate(['pricing']);
          })
        }
        else {
          Swal.fire({
            title: 'Oops...',
            html: data.status,
            icon: 'error',
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('users');
            localStorage.removeItem('cookie');
            this.router.navigate(['/']);
          })

        }
      }
      else {
        this.name = data.data.name;
        this.options = data.data.options;
        this.next = data.__links.next;
      }
    })

    this.data.getPageContent('colour-combination').subscribe((data: any) => {
      if (data.code == 200) {
        this.content = JSON.parse(data.content);
      }
    });

    this.data.checkEditStatus().subscribe((data: any) => {
      if (data.code == 200) {
        if(data.data==1){
        this.showEditButton=true
        }
      }
    });
  }

  modules = SharableService.Module;
  Toast = SharableService.Toast;

  updatContent: any;
  contentId: any;
  index: any;

  edit(contentId, index) {
    this.contentId = contentId;
    this.index = index;
    this.updatContent = this.content[index]['content'];
  }
  saveText() {
    this.updatContent=this.sharableService.removeExtraLineAtEnd(this.updatContent);
    this.content[this.index]['content'] = this.updatContent;
    this.data.setPageContent(this.contentId, this.updatContent).subscribe((data: any) => {
      if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: 'Changes has been saved !'
        })
      }
    });
  }

  onSelect(id, pageName) {

    this.setValue = id;
    // alert(this.setValue);
    this.data.setPage(id, pageName).subscribe((data: any) => {
      var res = JSON.parse(data.body);
      if (res.StatusCode == 202) {
        localStorage.setItem('cookie', data.cookie);
        this.router.navigate([this.next]);
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res.data.error,
        })
      }
    });
  }

  onClick(next, value) {
    if (value != null) {
      this.router.navigate([next]);

      // //      console.log(value);
      //       this.data.getVariety(next).subscribe((data: any)=>{
      //       this.users=data;
      //       this.name = data.data.name;
      //       this.next=data.__links.next;
      //       this.prev=data.__links.prev;
      //       this.options=data.data.options;
      //       // console.log(this.users);
      //       this.setValue=null;
      //    })
    }
    else {
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: 'Please Select Any Opal.',
      })
    }
  }

  //~~~~~~~~~~ Logout Function ~~~~~~~~~~~~~//
  onLogout() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to logout!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!'
    }).then((result) => {
      if (result.value) {
        this.authService.logoutUser();
      }
    })


  }

  onClickErr() {
    if (this.setValue == null) {
      Swal.fire({
        title: 'Oops...',
        text: "Please Select Any Opal.",
        icon: 'info',
      })
    }
    else {
      Swal.fire({
        title: 'Error',
        text: "First you have to select Colour saturation",
        icon: 'error',
      })
    }
  }

  startAgain() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to start again",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, i want!'
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['create-new-certificate']);
      }
    })

  }

  sendValue(data) {
    var transferData = data;
    this.router.navigate(['/colour-combination/photo-library', { title: transferData }]);
  }

}

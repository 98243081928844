import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from "ngx-cookie-service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';

import { NgxUiLoaderModule } from  'ngx-ui-loader';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';

import { NgxSpinnerModule } from "ngx-spinner";

import {LocationStrategy, HashLocationStrategy} from '@angular/common';
// import {MatProgressSpinnerModule, MatRadioModule, MatSliderModule} from '@angular/material'

import { PricingComponent } from './pricing/pricing.component';
import { ServicesComponent } from './services/services.component';
import { OpalInfoComponent } from './opal-info/opal-info.component';
import { HNavComponent } from './dashboard/h-nav/h-nav.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AccountSettingComponent } from './dashboard/account-setting/account-setting.component';
import { UserDetailsComponent } from './dashboard/user-details/user-details.component';
import { CreateNewCertificateComponent } from './dashboard/create-new-certificate/create-new-certificate.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CheckoutComponent } from './checkout/checkout.component';

import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';


import { BodyBrightnessComponent } from './dashboard/brightness/body-brightness.component';
import { TransparencyComponent } from './dashboard/transparency/transparency.component';
import { ColorCombinationComponent } from './dashboard/colour-combination/color-combination.component';
import { ColorSaturationComponent } from './dashboard/colour-saturation/color-saturation.component';
import { BodyOutlineComponent } from './dashboard/outline-shape/body-outline.component';
import { ProfileShapeComponent } from './dashboard/profile-shape/profile-shape.component';
import { OriginComponent } from './dashboard/origin/origin.component';
import { ColorPatternComponent } from './dashboard/color-pattern/color-pattern/color-pattern.component';
import { FaceColorDirectionalityComponent } from './dashboard/face-color-directionality/face-color-directionality.component';
import { FaceColorPercentageComponent } from './dashboard/colour-distribution/face-color-percentage.component';
import { InclusionPercentageComponent } from './dashboard/inclusion-percentage/inclusion-percentage.component';

import { CaretWeightComponent } from './dashboard/carat-weight/caret-weight.component';

import { UserCertifacateComponent } from './dashboard/user-certifacate/user-certifacate.component';
import { GeneratePdfComponent } from './dashboard/generate-pdf/generate-pdf.component';
import { DataTablesModule } from 'angular-datatables';


import { PaypalResponseComponent } from './paypal-response/paypal-response.component';

import { BodytoneComponent } from './dashboard/bodytone/bodytone/bodytone.component';

import { SharableService } from './sharable.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ActivateComponent } from './activate/activate.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { CreateACompaignComponent } from './dashboard/create-a-compaign/create-a-compaign.component';


import { PhotoLibraryComponent } from './photo-library/photo-library.component';
import { PhotolibraryComponent } from './dashboard/brightness/photolibrary/photolibrary.component';
import { ColourPhotolibraryComponent } from './dashboard/colour-saturation/colour-photolibrary/colour-photolibrary.component';
import { OutlinePhotolibraryComponent } from './dashboard/outline-shape/outline-photolibrary/outline-photolibrary.component';
import { OriginPhotolibraryComponent } from './dashboard/origin/origin-photolibrary/origin-photolibrary.component';
import { CcphotolibraryComponent } from './dashboard/colour-combination/ccphotolibrary/ccphotolibrary.component';
import { PricingLandComponent } from './pricing-land/pricing-land.component';
import { EduactionalVideosComponent } from './dashboard/eduactional-videos/eduactional-videos.component';
import { VarietyPhotolibraryComponent } from './dashboard/create-new-certificate/variety-photolibrary/variety-photolibrary.component';
import { BodytonePhotolibraryComponent } from './dashboard/bodytone/bodytone-photolibrary/bodytone-photolibrary.component';
import { CpPhotolibraryComponent } from './dashboard/color-pattern/cp-photolibrary/cp-photolibrary.component';
import { DirectionPhotolibraryComponent } from './dashboard/face-color-directionality/direction-photolibrary/direction-photolibrary.component';
import { ColourdisPhotolibraryComponent } from './dashboard/colour-distribution/colourdis-photolibrary/colourdis-photolibrary.component';
import { FacePhotolibraryComponent } from './dashboard/inclusion-percentage/face-photolibrary/face-photolibrary.component';

import { QuillModule } from 'ngx-quill';
import { PaymentDetailsComponent } from './dashboard/payment-details/payment-details.component';
import { UserCreationComponent } from './dashboard/user-creation/user-creation.component';
import { AddCouponComponent } from './dashboard/add-coupon/add-coupon.component';
import { CouponDetailsComponent } from './dashboard/coupon-details/coupon-details.component';
import { ImageSectionComponent } from './dashboard/image-section/image-section.component';
import { TopBannerComponent } from './top-banner/top-banner.component';
import { ShopifyPaymentsComponent } from './shopify-payments/shopify-payments.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    PricingComponent,
    ServicesComponent,
    OpalInfoComponent,
    HNavComponent,
    DashboardComponent,
    AccountSettingComponent,
    UserDetailsComponent,
    CreateNewCertificateComponent,
    FooterComponent,
    HeaderComponent,
    ContactUsComponent,
    CheckoutComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    BodyOutlineComponent,
    BodyBrightnessComponent,
    TransparencyComponent,
    ColorCombinationComponent,
    ColorSaturationComponent,
    BodyOutlineComponent,
    ProfileShapeComponent,
    FaceColorDirectionalityComponent,
    FaceColorPercentageComponent,
    InclusionPercentageComponent,
    CaretWeightComponent,

    UserCertifacateComponent,
    GeneratePdfComponent,
    PaypalResponseComponent,
    BodytoneComponent,
    OriginComponent,
    ColorPatternComponent,

    ActivateComponent,
    ForgotPasswordComponent,
    BottomNavComponent,
    SidebarComponent,
    CreateACompaignComponent,

  
    PhotoLibraryComponent,
    PhotolibraryComponent,
    ColourPhotolibraryComponent,
    OutlinePhotolibraryComponent,
    OriginPhotolibraryComponent,
    CcphotolibraryComponent,
    PricingLandComponent,
    EduactionalVideosComponent,
    VarietyPhotolibraryComponent,
    BodytonePhotolibraryComponent,
    CpPhotolibraryComponent,
    DirectionPhotolibraryComponent,
    ColourdisPhotolibraryComponent,
    FacePhotolibraryComponent,
    PaymentDetailsComponent,
    UserCreationComponent,
    AddCouponComponent,
    CouponDetailsComponent,
    ImageSectionComponent,
    TopBannerComponent,
    ShopifyPaymentsComponent,
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    // MatProgressSpinnerModule, MatRadioModule, MatSliderModule,
    BrowserAnimationsModule, ToastrModule.forRoot(),
    NgxUiLoaderModule,
    DataTablesModule,
    QuillModule.forRoot()
  ],
  providers: [
    SharableService,CookieService,{provide: LocationStrategy, useClass: HashLocationStrategy},
     { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

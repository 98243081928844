import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { DataService } from '../../data.service';

import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css']
})
export class AccountSettingComponent implements OnInit {

  constructor(private authService: AuthService, private data: DataService, private http: HttpClient, private router: Router) { }
  email: string;
  user: any;
  previousImgPath: string;
  payHisData: any = [];
  remainCertValue: any;
  cert = "Certificates";
  flag = false;

  ngOnInit() {
    const user = localStorage.getItem('user');
    this.user = JSON.parse(user);
    this.email = JSON.parse(user).email;
  }

  getPaymentHistory() {
    this.data.getPaymentHistory().subscribe((data: any) => {
      this.payHisData = data.data;
      this.remainCertValue = data.remainCertValue[0].available_certificate;
      if (this.remainCertValue <= 1) {
        this.cert = "Certificate"
      }
      this.flag = true;
    })
  }


  //~~~~~~~~~~~ Function of change profile image ~~~~~~~~~~~~//
  fileData = null;

  onFileSelected(event) {
    this.fileData = <File>event.target.files[0];
  }

  onSubmit() {
    const formData = new FormData();
    const email = this.email;
    formData.append('image', this.fileData);
    formData.append('email', email);
    formData.append('previousImgPath', this.previousImgPath);
    console.log(formData);
    
    this.http.post(this.data.url+'changeProfileImage', formData)
      .subscribe((res: any) => {
        if (res.code == 200) {
          localStorage.setItem('user', JSON.stringify(res.user));
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          Toast.fire({
            icon: 'success',
            title: res.message
          })
          this.router.navigate(['/dashboard']);

        }
      })
  }

  //~~~~~~~~~~~~~ Function of Change Password ~~~~~~~~//

  onChangePass(cng: NgForm) {
    // console.log(cng.value.crtPass);
    this.authService.changePassword(cng.value.crtPass, cng.value.password, cng.value.password_confirmation, this.email).subscribe((data: any) => {
      if (data.code == 200) {
        Swal.fire({
          icon: 'success',
          title: 'Password Updated!',
          text: data.message
        })
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.message
        })
      }

    },
      error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.errors.password[0],
        })
      }
    );
  }

  disValue = true;
  editButtonVal = 'Edit'
  editProfile() {
    this.editButtonVal = "Cancel"
    this.disValue = !this.disValue;
  }


  errType = "";
  updateAccounts(accData: NgForm) {

    this.authService.updateAccounts(accData.value.newNm, accData.value.mob).subscribe((data: any) => {

      console.log(data)
      if (data.code == '200') {


        localStorage.setItem('user', JSON.stringify(data.user));
        Swal.fire({
          icon: 'success',
          title: 'Details Updated!',
          text: data.message
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['dashboard']);
          }
        })

      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong please try again'
        })
      }

    },
      error => {
        console.log(error)

        if (error.error.errors.name) {
          this.errType = error.error.errors.name[0];
        }
        else if (error.error.errors.mobile) {
          this.errType = error.error.errors.mobile[0];
        }

        // this.toastr.errorToastr(this.errType);
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'error',
          title: this.errType
        })
      })
  }


}

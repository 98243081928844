import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  h1 = true;
  model = '';
  token = '';

  loggedIn = false;
  isLoggedIn = false;

  errType = '';

  constructor(private authService: AuthService, private router: Router, public toastr: ToastrManager, private ngxUi: NgxUiLoaderService) { }

  ngOnInit() {
    if (localStorage.getItem('access_token')) {
      this.loggedIn = true;
      this.authService.validateToken().subscribe((data: any) => {
        if (data.code != 200) {
          Swal.fire({
            title: 'Your Session Has Expired !',
            text: "You have to do login again.",
            icon: 'error',
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.value) {
              localStorage.removeItem('access_token');
              localStorage.removeItem('users');
              window.location.reload();
              this.loggedIn = false;
            }
          })
        }
      });
    }
  }

  onLogin(l: NgForm) {
    this.ngxUi.start();
    this.authService.loginUser(l.value.email, l.value.password,l.value.remember_me);

    this.authService.isUserLoggedIn.subscribe((val: any) => {
      this.loggedIn = val;
      this.router.navigateByUrl('/dashboard');
    }
    );
    this.ngxUi.stop();
  }


  /////****logout*****///////

  onLogOut() {
    this.authService.logoutUser();
  }

  onRegister(f: NgForm) {
    this.ngxUi.start();
    if(f.value.chk){
       this.authService.register(f.value.email,f.value.password, f.value.password_confirmation)
       .subscribe(
        (data: any) => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          Toast.fire({
            icon: 'success',
            title: data.message
          })
        },
        error => {
          console.log(error)
          if (error.error.errors.email) {
            this.errType = error.error.errors.email[0];
          }
          else if (error.error.errors.name) {
            this.errType = error.error.errors.name[0];
          }
          else if (error.error.errors.username) {
            this.errType = error.error.errors.username[0];
          }
          else if (error.error.errors.password) {
            this.errType = error.error.errors.password[0];
          }

          // this.toastr.errorToastr(this.errType);
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          Toast.fire({
            icon: 'error',
            title: this.errType
          })
        }
      );
    }
    this.ngxUi.stop();
  }


  onForgot(fgt: NgForm) {
    // console.log(fgt.value.email);
    this.authService.forgot(fgt.value.email).subscribe((data: any) => {
      // console.log(data);
      this.toastr.successToastr(data.message);
    },
      error => {
        this.toastr.errorToastr(error.error.message);
      }
    );
  }

  checkBoxFlag=false;
  checkCheckBox(){
    this.checkBoxFlag=!this.checkBoxFlag;
  }



}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharableService } from '../sharable.service';
import { DataService } from '../data.service';
@Component({
  selector: 'app-photo-library',
  templateUrl: './photo-library.component.html',
  styleUrls: ['./photo-library.component.css']
})
export class PhotoLibraryComponent implements OnInit {

  constructor(private router: Router,private dataService: DataService,private share: SharableService,) { }
  showEditButton = false;
  imagePath: any;

  ngOnInit() {
    this.imagePath = this.dataService.baseUrl + 'assets/dashboard_images/';
    this.dataService.checkEditStatus().subscribe((data: any) => {
      if (data.code == 200) {
        if(data.data==1){
        this.showEditButton=true
        }
      }
    });
  }

  sendValue(page,data){
    this.router.navigate(['/'+page+'/photo-library',{title:data}]);
   }

  Toast = SharableService.Toast;
  img_name: any;
  fileData: any;
  onLoad(img_name) {
    this.img_name = img_name;
  }

  updateImage(event) {
    const con_cat='/';
    this.fileData = <File>event.target.files[0];
    
    // 0 website and 1 for dashboard
    this.dataService.updateImage(this.fileData,this.img_name,1).subscribe((data: any) => {
      if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: data.message
        })
        this.imagePath=this.imagePath+con_cat;
      }
      else if (data.code == 406) {
        this.Toast.fire({
          icon: 'error',
          title: data.message
        })
      }
    });
  }

}

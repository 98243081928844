import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../data.service';
import { SharableService } from '../../sharable.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Type } from '@angular/compiler/src/core';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon-details.component.html',
  styleUrls: ['./coupon-details.component.css']
})
export class CouponDetailsComponent implements OnInit {

  constructor(private ngxLoader: NgxUiLoaderService, private router: Router, private data: DataService) { }

  flag = false;
  couponDetailsData: any = [];
  Toast = SharableService.Toast;

  ngOnInit() {
    this.data.getDetails('coupons').subscribe((data: any) => {
      if (data.code == 200) {
        this.couponDetailsData = data.data;
        this.flag = true;
      }
    })
  }

  doTheBack(){
    window.history.back();
  }

  action(type, id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to " + type + " !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        if (type == "delete") {
          this.data.removeAction('coupons', id).subscribe((data: any) => {
            if (data.code == 200) {
              this.data.getDetails('coupons').subscribe((data: any) => {
                if (data.code == 200) {
                  this.couponDetailsData = data.data;
                }
              })
              // let currentUrl = this.router.url;
              // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              // this.router.onSameUrlNavigation = 'reload';
              // this.router.navigate([currentUrl]);
              this.Toast.fire({
                icon: 'success',
                title: data.message
              })
            }
            if (data.code === 400) {
              this.Toast.fire({
                icon: 'error',
                title: data.message
              })
            }
          })
        }
        else {
          this.data.updateAction('coupons', id,type).subscribe((data: any) => {
            if (data.code == 200) {
              this.data.getDetails('coupons').subscribe((data: any) => {
                if (data.code == 200) {
                  this.couponDetailsData = data.data;
                }
              })
              this.Toast.fire({
                icon: 'success',
                title: data.message
              })
            }
            if (data.code === 400) {
              this.Toast.fire({
                icon: 'error',
                title: data.message
              })
            }
          })
        }
      }
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpResponse } from "@angular/common/http";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //  url="http://dev.opalapi.com/api/";
  
   url = "https://opallv.globusdemos.com/api/";
  // url = "https://lv.opalinfo.com/api/";
  // url = "http://127.0.0.1:8000/api/";

  checkStatus = new BehaviorSubject<boolean>(false);
  isUserLoggedIn = this.checkStatus.asObservable();

  constructor(private http: HttpClient, public toastr: ToastrManager, private router: Router) { }

  checkLogin() {
    const token = localStorage.getItem('access_token');
    if (token) {
      this.checkStatus.next(true);
    }
    else {
      this.checkStatus.next(false);
    }
  }

  imageUrl: string;
  args: any;

  loginUser(email: string, password: string, remember_me: any) {

    return this.http.post(this.url + 'login', { email, password, remember_me }).subscribe((checkUser: any) => {
      if (checkUser.token) {
        localStorage.setItem('access_token', checkUser.token);
        localStorage.setItem('user', JSON.stringify(checkUser.user));
        localStorage.setItem('cookie', null);
        this.checkLogin();
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'success',
          title: 'Logged in successfully'
        })
      }
    },
      error => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })

        Toast.fire({
          icon: 'error',
          title: error.error.message
        })
      }
    )
  }

  logoutUser() {
    return this.http.post(this.url + 'logout', { token: localStorage.getItem('access_token') }).subscribe((data: any) => {
      if (data.message) {
        localStorage.clear();

        this.checkLogin();
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'success',
          title: 'Logout successfully'
        })
        this.router.navigate(['/'])
      }
    },
      errror => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        this.router.navigate(['/'])
      }
    )
  }


  ///////////////////
  isLoggedIn: boolean;

  register(email, password, password_confirmation) {

    // console.log("Register auth..................."+name, username,email,tel,password,password_confirmation);
    return this.http.post(
      this.url + 'register',
      { email: email, password: password, password_confirmation: password_confirmation }
    );
  }

  forgot(email) {
    return this.http.post(
      this.url + 'forgot',
      { email: email }
    );
  }

  sendMsg(name, email, phone, query) {
    return this.http.post(this.url + 'contact', { name: name, email: email, phone: phone, query: query });
  }

  validate(token) {
    this.http.get(this.url + 'activate/' + token).subscribe((data: any) => {
      if (data.code == 200) {
        this.toastr.successToastr(data.message);
        this.router.navigate(['/'])
      }
      else {
        this.toastr.errorToastr(data.message);
        this.router.navigate(['/']);
      }
    });
  }

  forgotTokenValidate(token) {
    return this.http.get(this.url + 'forgotTokenValidate/' + token);
  }

  forgotPasswordForm(password, password_confirmation, token) {
    return this.http.post(this.url + 'forgotPasswordUpdate/' + token, { password, password_confirmation });
  }

  validateToken() {
    return this.http.get(
      this.url + 'validateToken',
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) }
    );
  }

  changePassword(cngPass, password, password_confirmation, email) {
    return this.http.post(this.url + 'changePassword', { cngPass, password, password_confirmation, email }

    );

  }

  checkValidity() {
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'checkValidity', { id })
  }

  updateAccounts(name, mobile) {
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'updateAccounts', { id, name, mobile })
  }


}

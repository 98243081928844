import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  status=false;
  message='';

  constructor(private authService:AuthService,private ngxLoader: NgxUiLoaderService) { }

ngOnInit() {
    this.ngxLoader.start(); 
  }

 ngAfterContentInit(){
     this.ngxLoader.stop();
  }

    onContact(cnt: NgForm){
      // console.log(cnt.value.name,cnt.value.email,cnt.value.phone,cnt.value.query)
      this.authService.sendMsg(cnt.value.name,cnt.value.email,cnt.value.phone,cnt.value.query).subscribe((data:any)=>{
        if(data.code==200)
        {
          this.status=true;
          this.message=data.message;
        }
      });

      }

}

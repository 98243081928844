import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../../auth.service';
import { DataService } from '../../data.service';
import { SharableService } from '../../sharable.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {

  constructor(private authService: AuthService,private ngxLoader: NgxUiLoaderService,private router: Router, private data: DataService) { }

  paymentDetailsData: any = [];
  flag=false;
  ngOnInit() {
    this.ngxLoader.start(); 
    this.data.getDetails('paymentDetails').subscribe((data:any)=>{
      if(data.code==200){
        this.paymentDetailsData = data.data;
        this.flag=true;
        $('#appsidebaroverlay').animate({ scrollTop: 600 }, 0);
      }
     })
     this.ngxLoader.stop(); 
  }

  doTheBack(){
    window.history.back();
  }

}

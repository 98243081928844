import { Component, OnInit, AfterContentInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharableService } from '../sharable.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data.service';
@Component({
  selector: 'app-opal-info',
  templateUrl: './opal-info.component.html',
  styleUrls: ['./opal-info.component.css']
})
export class OpalInfoComponent implements AfterContentInit {

  constructor(private dataService: DataService,  private http: HttpClient,private share: SharableService, private ngxLoader: NgxUiLoaderService) { }

  content: any[][] = [[], []];
  showEditButton = false;
  imagePath: any;
  dashboardImagePath:any;

  ngOnInit() {
    this.imagePath = this.dataService.baseUrl + 'assets/website_images/';
    this.dashboardImagePath = this.dataService.baseUrl + 'assets/dashboard_images/';
    window.scroll(0, 0);
    this.ngxLoader.start();

    if (this.share.scrollValue != null) {
      document.querySelector('#' + this.share.scrollValue).scrollIntoView();
      this.share.scrollValue = null;
    }
    this.dataService.getPageContent('characteristic').subscribe((data: any) => {
      if (data.code == 200) {
        this.content = JSON.parse(data.content);
        console.log(this.content);
      }
    });
    // this.showEditButton = this.share.getGlobalVar();
    if (localStorage.getItem('user')) {
      this.dataService.checkEditStatus().subscribe((data: any) => {
        if (data.code == 200) {
          if(data.data==1){
          this.showEditButton=true
          }
        }
      });
    }
  }

  ngAfterContentInit() {
    this.ngxLoader.stop();
  }

  scroll(idValue) {
    document.querySelector('#' + idValue).scrollIntoView({ behavior: "smooth" });
  }



  modules = SharableService.Module;
  Toast = SharableService.Toast;

  updatContent: any;
  contentId: any;
  index: any;

  edit(contentId, index) {
    this.contentId = contentId;
    this.index = index;
    this.updatContent = this.content[index]['content'];
  }
  saveText() {
    this.updatContent=this.share.removeExtraLineAtEnd(this.updatContent);
    console.log(this.updatContent);
    this.content[this.index]['content'] = this.updatContent;
    this.dataService.setPageContent(this.contentId, this.updatContent).subscribe((data: any) => {
      if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: 'Changes has been saved !'
        })
      }
    });
  }

  img_name: any;
  fileData: any;
  onLoad(img_name) {
    this.img_name = img_name;
  }

  updateImage(event) {
    const con_cat='/';
    this.fileData = <File>event.target.files[0];
    
    // 0 website and 1 for dashboard
    this.dataService.updateImage(this.fileData,this.img_name,0).subscribe((data: any) => {
      if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: data.message
        })
        this.imagePath=this.imagePath+con_cat;
      }
      else if (data.code == 406) {
        this.Toast.fire({
          icon: 'error',
          title: data.message
        })
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharableService } from '../../sharable.service';
import { AuthService } from '../../auth.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-create-new-certificate',
  templateUrl: './create-new-certificate.component.html',
  styleUrls: ['./create-new-certificate.component.css']
})
export class CreateNewCertificateComponent implements OnInit {


  users: object;
  // selectValue:object;
  name: any;
  options: any = [];
  next: any;
  prev: any;
  fullName: string;
  setValue = null;
  imagePath: string;
  dashboardImagePath: string;
  modal: string;
  content: any[][] = [[], []];
  showEditButton = false;

  constructor(private share: SharableService, private data: DataService, private router: Router, private cookies: CookieService, private ngxLoader: NgxUiLoaderService, private authService: AuthService) {
    this.name = '';
    this.options = '';
    this.next = '';
    this.prev = '';

  }

  ngOnInit() {
    if (!localStorage.getItem('tc')) {
      this.modal = 'show'
    }
    const user = localStorage.getItem('user');
    this.fullName = JSON.parse(user).name;
    this.imagePath = this.data.imgUrl + JSON.parse(user).image_path;
    this.dashboardImagePath = this.data.baseUrl + 'assets/dashboard_images/';
    this.ngxLoader.start();
    this.data.startSession()
      .subscribe((data: any) => {
        // console.log(data);
      })

    this.data.getVariety('variety').subscribe((data: any) => {
      if (data.code == 400 || data.code == 401 || data.code == 402 || data.code == 204) {
        if (data.code == 204) {
          Swal.fire({
            title: 'Thanks...',
            html: data.status,
            icon: 'info',
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Continue'
          }).then((result) => {
            this.router.navigate(['pricing']);
          })
        }
        else {
          Swal.fire({
            title: 'Oops...',
            html: data.status,
            icon: 'error',
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            localStorage.clear();
            this.router.navigate(['/']);
          })

        }
      }
      else {
        this.users = data;
        this.name = data.data.name;
        this.options = data.data.options;
        this.next = data.__links.next;
      }
    });


    this.data.getPageContent('variety').subscribe((data: any) => {
      if (data.code == 200) {
        this.content = JSON.parse(data.content);
      }
    });

    this.data.checkEditStatus().subscribe((data: any) => {
      if (data.code == 200) {
        if(data.data==1){
        this.showEditButton=true
        }
      }
    });
    this.ngxLoader.stop();
  }


  modules = SharableService.Module;
  Toast = SharableService.Toast;

  updatContent: any;
  contentId: any;
  index: any;

  edit(contentId, index) {
    this.contentId = contentId;
    this.index = index;
    this.updatContent = this.content[index]['content'];
  }
  saveText() {
    this.updatContent=this.share.removeExtraLineAtEnd(this.updatContent);
    this.content[this.index]['content'] = this.updatContent;
    this.data.setPageContent(this.contentId, this.updatContent).subscribe((data: any) => {
      if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: 'Changes has been saved !'
        })
      }
    });
  }

  onClick(next, value) {
    if (value != null) {
      this.router.navigate([next]);
    }
    else {
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: 'Please Select Any Opal.',
      })
    }
  }


  onClickPrev(next) {
    this.data.getVariety(next).subscribe((data: any) => {
      this.users = data;
      this.name = data.data.name;
      this.next = data.__links.next;
      this.prev = data.__links.prev;
      this.options = data.data.options;
      this.setValue = null;
    })
  }


  onSelect(id, pageName) {
    this.setValue = id;
    this.cookies.set('varietyValue', id);
    this.data.setPage(id, pageName).
      subscribe((data: any) => {
        console.log(data);
        var res = JSON.parse(data.body);
        if (res.StatusCode == 202) {
          localStorage.setItem('cookie', data.cookie);
          this.router.navigate([this.next]);
        }
      });
  }

  //~~~~~~~~~~ Logout Function ~~~~~~~~~~~~~//
  onLogout() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to logout!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!'
    }).then((result) => {
      if (result.value) {
        this.authService.logoutUser();
      }
    })
  }

  onClickErr() {
    if (this.setValue == null) {
      Swal.fire({
        title: 'Oops...',
        text: "Please Select Any Opal.",
        icon: 'info',
      })
    }
    else {
      Swal.fire({
        title: 'Oops...',
        text: "First you have to select body-tone",
        icon: 'error',
      })
    }
  }

  removeClass() {
    this.modal = '';
    localStorage.setItem('tc', 'true');
  }

  scroll(scrollValue) {
    this.share.scrollValue = scrollValue;
    this.router.navigate(['/opal-characteristics']);
  }

  sendValue(data) {
    var transferData = data.split(' ').join('-').toLowerCase();
    this.router.navigate(['/variety/photo-library', { title: transferData }]);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  baseUrl = "https://opallv.globusdemos.com/";
  // baseUrl = "https://lv.opalinfo.com/";
  // baseUrl = "http://127.0.0.1:8000/";
  // baseUrl = "http://dev.opalapi.com/";

  url = this.baseUrl + "api/";
  imgUrl = this.baseUrl + "profileImages/";
  
  constructor(private http: HttpClient, private ngxLoader: NgxUiLoaderService) { }

  headers1 = new HttpHeaders().set('X-Requested-With', 'XMLHttpRequest');
  hd = new HttpHeaders().set('Access-Control-Allow-Origin', 'http://localhost:4202');
  param = null;
  param2 = null;
  getVariety(next) {
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'variety', { id, next }, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  setPage(id, pageName) {
    const cookie = localStorage.getItem('cookie');
    return this.http.post(this.url + 'setPage', { id: id, page: pageName, cookie: cookie });
  }

  startSession() {
    return this.http.get(this.url + 'startSession');
  }

  getCaretWeight(cw, mu, cc, ct, type) {
    const cookie = localStorage.getItem('cookie');
    return this.http.post(this.url + 'caretWeight', { cw, mu, cc, ct, type, cookie });
  }

  valAsses() {
    const cookie = localStorage.getItem('cookie');
    return this.http.post(this.url + 'valAsses', { cookie });
  }

  saveCertificate(name, certValue: any) {
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'saveCertificate', { id, name, certValue });
  }

  getAllCertificate() {
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'getAllCertificate', { id });
  }

  //~~~~~~function of get specific certificate value ~~~~~~~~~~~//
  viewCertificate(id) {
    return this.http.post(this.url + 'getSpecificCertificate', { id });
  }

  // Function to get Shopify Order Details
  viewShopifyOrderDetails(pre_order_id){
    return this.http.post(this.url + 'getShopifyOrderDetails', { pre_order_id});
  }

  onPay(amount: any, mode: any,pricing_id:any,coupon_id) {
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    this.ngxLoader.start();
    return this.http.post(this.url + 'paypal/ec-checkout', { amount, mode, id, pricing_id,coupon_id}).subscribe((data: any) => {
      if (data.link) {
        window.open(data.link, '_self');
        this.ngxLoader.stop();
      }
    })
  }

  payHistory() {
    return this.http.post(this.url + 'payment-history', { id: 11 }).subscribe((data: any) => {
    });
  }

  // dashboard(){
  //   return this.http.get(
  //     this.url+'dashboard',
  //     { headers: new HttpHeaders({'Authorization': 'Bearer '+localStorage.getItem('access_token')}) }
  //     );
  // }

  getPaymentHistory() {
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'getPaymentHistory', { id });
  }

  createCompaign(cValue, img_path_arr, cpgnName) {
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'createCampaign', { cValue, id, cpgnName,img_path_arr });
  }

  getAllCampaign() {
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'getAllCampaign', { id });
  }

  certificateLength(id, campId) {
    return this.http.post(this.url + 'certificateLength', { id, campId });
  }


  getPageContent(type) {
    return this.http.post(this.url + 'getPageContent', {
      type
    },{ headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  setPageContent(contentId, content) {
    return this.http.post(this.url + 'saveUpdateContent', {
      contentId, content
    },{ headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  updateImage(fileData:File, img_name,type) {
    const formData = new FormData();
    formData.append('image',fileData);
    formData.append('img_name',img_name);
    formData.append('img_type',type);
    return this.http.post(this.url + 'updateImage',formData);
  }

  checkEditStatus(){
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'checkEditStatus',{id},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }
  updateEditStatus(flag){
    const user = localStorage.getItem('user');
    const id = JSON.parse(user).id;
    return this.http.post(this.url + 'updateEditStatus',{id,flag},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  getDetails(details){
    return this.http.post(this.url + 'getDetails',{details},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  userAction(type,id){
    return this.http.post(this.url + "userAction",{type,id},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  removeAction(table_name,id){
    return this.http.post(this.url + "admin/removeAction",{table_name,id},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  updateAction(table_name,id,type){
    return this.http.post(this.url + "admin/updateAction",{table_name,id,type},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  viewUserDetails(id){
    return this.http.post(this.url + "viewUserDetails",{id},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  getGalleryImages(type){
    return this.http.post(this.url + "getGalleryImages",{type},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }
  
  removeGalleryImg(type, value){
    return this.http.post(this.url + "removeGalleryImg",{type,value},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  uploadGallImg(fileData:File,type) {
    const formData = new FormData();
    formData.append('image',fileData);
    formData.append('folder_type',type);
    return this.http.post(this.url + 'uploadGallImg',formData);
  }

  createUser(email, password, password_confirmation,quantity,is_activated){
    return this.http.post(this.url + "createUser",{email, password, password_confirmation,quantity,is_activated},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }
  
  addCoupon(coupons){
    return this.http.post(this.url + "admin/addCoupon",{coupons},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  verifyCoupon(coupon_code,pricing_id){
    return this.http.post(this.url + "verifyCoupon",{coupon_code,pricing_id},
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  getEmailTemplateImg(){
    return this.http.get(`${this.url}getEmailTemplateImg`,
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }

  uploadEmailTemplateImg(fileDatas:File) {
    const formData = new FormData();
    formData.append('images',JSON.stringify(fileDatas));
    return this.http.post(`${this.url}uploadEmailTemplateImg`,formData,
    { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') })});
  }
  removeEmailTemplateImage(imgId:any){
      return this.http.post(this.url + "removeEmailTemplateImage",{imgId},
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }) });
  }
}

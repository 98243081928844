import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import   Swal   from 'sweetalert2'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private authService:AuthService,private router:Router) { }

ngOnInit() {
      /* added by alok for redirection checkout page*/
     if (JSON.parse(localStorage.getItem('checkout'))) {
       localStorage.removeItem('checkout');
       this.router.navigateByUrl('/pricing/checkout');
     }
     /*end alok*/
  }

}

import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../data.service';
import { SharableService } from '../../sharable.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  constructor(private ngxLoader: NgxUiLoaderService,private router: Router, private data: DataService) { }

  flag=false;
  userDetailsData: any = [];
  imageBase=this.data.baseUrl+"profileImages/";
  ngOnInit() {
    this.ngxLoader.start(); 
    
    this.data.getDetails('users').subscribe((data:any)=>{
     if(data.code==200){
      this.userDetailsData = data.data;
      this.flag=true;
     }
    })
  }

 ngAfterContentInit(){
     this.ngxLoader.stop();
  }

  doTheBack(){
    window.history.back();
  }

  Toast=SharableService.Toast;

  reCheck(type,id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to "+type+" !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.data.userAction(type,id).subscribe((data:any)=>{
          if(data.code == 200){
            this.data.getDetails('users').subscribe((data:any)=>{
              if(data.code==200){
               this.userDetailsData = data.data;
              }
             })
            // let currentUrl = this.router.url;
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // this.router.onSameUrlNavigation = 'reload';
            // this.router.navigate([currentUrl]);
            this.Toast.fire({
              icon: 'success',
              title: data.message
            })
          }
          if(data.code === 400){
            this.Toast.fire({
              icon: 'error',
              title: data.message
            })
          }
        })
      }
    })
  }
  
  userDetails:any=[];
  viewUserDetails(id){
    this.data.viewUserDetails(id).subscribe((data:any)=>{
      if(data.code === 200){
        this.userDetails=data.data[0];
      }
      if(data.code === 400){
        this.Toast.fire({
          icon: 'error',
          title: data.message
        })
      }
    })
  }

}

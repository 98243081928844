import { Component, OnInit,Input  } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { SharableService } from '../../sharable.service';
import   Swal   from 'sweetalert2'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  constructor(private router:Router, private data: DataService,private sharable: SharableService) { }
  fullName:any;
  imagePath:any;
  checked=false;
  showEdiFlag:any;
  
  creat_a_camp=this.router.url == '/dashboard/create-a-compaign' ? 'show':'';
  man_use:any;
  man_coupon:any;
  
  url=this.router.url;
 

  ngOnInit() {
    if(this.url == '/admin/user-details' || this.url == '/admin/user-creation' ){
      this.man_use='show';
    }
    else{
      this.man_use='';
    }
    if(this.url == '/admin/add-coupon' || this.url == '/admin/coupon-details' ){
      this.man_coupon='show';
    }
    else{
      this.man_coupon='';
    }
    const user=localStorage.getItem('user');
    this.fullName=JSON.parse(user).name;
    this.imagePath=this.data.imgUrl+JSON.parse(user).image_path;

    this.data.checkEditStatus().subscribe((data: any) => {
      if (data.code == 200) {
        if(data.admin==1){
          this.showEdiFlag=true;
          if(data.data==1){
            this.checked=true
          }
        }
        else{
          this.showEdiFlag=false;
        }
      }
    });
  }

  onClick(next){
    this.router.navigate([next]); 
  }

  onClickErr(){
    Swal.fire({
      title: 'Sorry....',
      text: "Please Select variety first.",
      icon: 'info',
    })
  }
  flag=false;
  Toast = SharableService.Toast;


  activeEc(){
    var flag=1;
    if(this.checked){
      flag=0;
    }
  this.data.updateEditStatus(flag).subscribe((data:any)=>{
     if(data.code==200){
       if(flag==1){
        this.Toast.fire({
          icon: 'success',
          title: 'You are in edit mode.'
        })
       }
       else{
          this.Toast.fire({
            icon: 'success',
            title: 'You are out of edit mode.'
          })
         }
     }
  });
  }
}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharableService } from '../sharable.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private dataService: DataService,private ngxLoader: NgxUiLoaderService,private sharableService: SharableService) { }

  content: any[][]=[[],[]];
  showEditButton=false;

  ngOnInit() {
    window.scroll(0,0);
    this.ngxLoader.start();

    this.dataService.getPageContent('privacy').subscribe((data: any) => {
      if(data.code==200){
        this.content=JSON.parse(data.content);
        console.log(this.content);
      }
     });

     if (localStorage.getItem('user')) {
      this.dataService.checkEditStatus().subscribe((data: any) => {
        if (data.code == 200) {
          if(data.data==1){
          this.showEditButton=true
          }
        }
      });
    }
  }

  ngAfterContentInit() {
    this.ngxLoader.stop();
  }

  modules=SharableService.Module;
  Toast=SharableService.Toast;

  updatContent:any;
  contentId:any;
  index:any;

  edit(contentId,index){
   this.contentId=contentId;
   this.index=index;
   this.updatContent=this.content[index]['content'];
  }
  saveText(){
    this.updatContent=this.sharableService.removeExtraLineAtEnd(this.updatContent);
    this.content[this.index]['content']=this.updatContent;
    this.dataService.setPageContent(this.contentId,this.updatContent).subscribe((data: any) => {
      if(data.code==200){
        this.Toast.fire({
          icon: 'success',
          title: 'Changes has been saved !'
        })       
      }
     });
  }

}

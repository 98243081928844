import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-paypal-response',
  templateUrl: './paypal-response.component.html',
  styleUrls: ['./paypal-response.component.css']
})
export class PaypalResponseComponent implements OnInit {

  constructor(private data: DataService, private  route: ActivatedRoute) { }
 
  ref:any;

  ngOnInit() {
    this.ref=this.route.snapshot.paramMap.get('ref');
    //  this.data.payHistory();
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  
  status=false;
  constructor(private route : ActivatedRoute,private authService: AuthService,public toastr: ToastrManager,private router:Router) { }
  token:string;
  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.authService.forgotTokenValidate(this.token).subscribe((data:any)=>{ 
      if(data.code==200){
        this.status=true;
      }
    },
  error=>{
    console.log(error);
          this.toastr.errorToastr(error.error.message, 'Oops!');
          this.router.navigate(['/']);
       }
    );
  }

  forgotPasswordForm(fpf: NgForm){
    this.authService.forgotPasswordForm(fpf.value.password,fpf.value.password_confirmation,this.token).subscribe((data:any)=>{
      if(data.code==200){
           const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
                 }  )

                 Toast.fire({
                  icon: 'success',
                  title: data.message
                })

                this.router.navigate(['/']);
                

      }
       else{
           Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.message
        })
      }
    },
    error=>{
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.errors.password[0],
        })
    });
  }

}

import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from './data.service';
import { SharableService } from './sharable.service';
import { Subject } from 'rxjs';

import { NgxUiLoaderService } from 'ngx-ui-loader';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: Subject<boolean> = this.sharableService.isLoading;


  title = 'opalHome';

  constructor(private spinner: NgxSpinnerService, private sharableService: SharableService, private dataService: DataService, private ngxLoader: NgxUiLoaderService,) { 

  }

  ngOnInit() {
    this.ngxLoader.start();

  }

  ngAfterContentInit() {
    this.ngxLoader.stop();
  }
}

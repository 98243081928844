import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../data.service';
import { SharableService } from '../../sharable.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-image-section',
  templateUrl: './image-section.component.html',
  styleUrls: ['./image-section.component.css']
})
export class ImageSectionComponent implements OnInit {

  constructor(private ngxLoader: NgxUiLoaderService, private data: DataService) { }

  imageDatas: any;
  imgUploadingFlag=false;
  basePath:any;
  ngOnInit() {
    this.basePath= this.data.baseUrl;
    this.ngxLoader.start();
    this.showEmailTempImages();
    this.ngxLoader.stop();
  }

  showEmailTempImages(){
    this.data.getEmailTemplateImg().subscribe((data: any) => {
      if (data.code == 200) {
        this.imageDatas = data.data;
      }
    })
  }

  Toast = SharableService.Toast;
  images: any=[];
  file:any;
  getFiles(event) {
    this.file=event;
    this.images = [];
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  uploadGallImg() {
    if(this.images.length===0){
      this.Toast.fire({
        icon: 'error',
        title: 'please select the image.'
      })
      return true;
    }
    this.imgUploadingFlag=true;
    this.data.uploadEmailTemplateImg(this.images).subscribe((data: any) => {
      if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: data.message
        });
        $('#fileN').val('');
        this.imgUploadingFlag=false;
        this.showEmailTempImages();
      }
      else if (data.code == 406) {
        this.Toast.fire({
          icon: 'error',
          title: data.message
        })
      }
    });
  }

  removeImage(imgId) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to remove!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if (result.value) {
       this.data.removeEmailTemplateImage(imgId).subscribe((data:any)=>{
        if (data.code == 200) {
          this.Toast.fire({
            icon: 'success',
            title: data.message
          })
          this.showEmailTempImages();
        }
       });
      }
    })
  }

  doTheBack() {
    window.history.back();
  }

  copyOnClick(e:any){
    e.select();
    document.execCommand('copy');
    e.setSelectionRange(0, 0);
    this.Toast.fire({
      icon: 'success',
      title: 'Copied to clipboard.'
    })
  }
}

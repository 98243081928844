import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import { AuthService } from '../../../auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharableService } from '../../../sharable.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-bodytone',
  templateUrl: './bodytone.component.html',
  styleUrls: ['./bodytone.component.css']
})
export class BodytoneComponent implements OnInit {

  constructor(private data: DataService,private sharableService: SharableService, private router: Router, private cookies: CookieService, private authService: AuthService, private ngxLoader: NgxUiLoaderService) { }
  users: object;
  name: any;
  options: any = [];
  flag = false;
  next: any;
  prev: any;
  test: any;
  setValue = null;

  vv: any;
  buttonStatus1 = true;
  buttonStatus2 = true;
  buttonStatus3 = true;
  fullName: string;
  imagePath: string;
  content: any[][] = [[], []];
  showEditButton=false;
  dashboardImagePath = this.data.baseUrl + 'assets/dashboard_images/';


  ngOnInit() {
    this.ngxLoader.start();
    const user = localStorage.getItem('user');
    this.fullName = JSON.parse(user).name;
    this.imagePath = this.data.imgUrl + JSON.parse(user).image_path;
    this.vv = this.cookies.get('varietyValue');
    if (this.vv == 16) {
      this.buttonStatus1 = false;
    }
    else if (this.vv == 17) {
      this.buttonStatus2 = false;
    }
    else if (this.vv == 18) {
      this.buttonStatus3 = false;
    }
    else if (this.vv == 19) {
      this.buttonStatus1 = false;
      this.buttonStatus2 = false;
      this.buttonStatus3 = false;
    }

    //  console.log(this.cookies.get('varietyValue'));
    this.data.getVariety('body-tone').subscribe((data: any) => {
      if (data.code == 400 || data.code == 401 || data.code == 402 || data.code == 204) {
        if (data.code == 204) {
          Swal.fire({
            title: 'Thanks...',
            html: data.status,
            icon: 'info',
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Continue'
          }).then((result) => {
            this.router.navigate(['pricing']);
          })
        }
        else {
          Swal.fire({
            title: 'Oops...',
            html: data.status,
            icon: 'error',
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('users');
            localStorage.removeItem('cookie');
            this.router.navigate(['/']);
          })

        }
      }
      else {
        this.users = data;

        this.name = data.data.name;
        this.options = data.data.options;
        this.next = data.__links.next;
        this.flag = true;
      }

    })
    this.data.getPageContent('body-tone').subscribe((data: any) => {
      if (data.code == 200) {
        this.content = JSON.parse(data.content);
      }
    });
    
    this.data.checkEditStatus().subscribe((data: any) => {
      if (data.code == 200) {
        if(data.data==1){
        this.showEditButton=true
        }
      }
    });
    
    this.ngxLoader.stop();
  }

  modules = SharableService.Module;
  Toast = SharableService.Toast;

  updatContent: any;
  contentId: any;
  index: any;

  edit(contentId, index) {
    this.contentId = contentId;
    this.index = index;
    this.updatContent = this.content[index]['content'];
  }
  saveText() {
    this.updatContent=this.sharableService.removeExtraLineAtEnd(this.updatContent);
    this.content[this.index]['content'] = this.updatContent;
    this.data.setPageContent(this.contentId, this.updatContent).subscribe((data: any) => {
      if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: 'Changes has been saved !'
        })
      }
    });
  }

  onSelect(id, pageName) {

    this.setValue = id;
    this.data.setPage(id, pageName).subscribe((data: any) => {
      console.log(data);
      var res = JSON.parse(data.body);
      if (res.StatusCode == 202) {
        localStorage.setItem('cookie', data.cookie);
        this.router.navigate([this.next]);
      }
    });

  }

  onClick(next, value) {
    if (value != null) {
      this.router.navigate([next]);
    }
    else {
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: 'Please Select Any Opal.',
      })
    }
  }

  //   demo(){
  //   this.router.navigate(['/create-new-certificate']);
  // }

  //~~~~~~~~~~ Logout Function ~~~~~~~~~~~~~//
  onLogout() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to logout!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!'
    }).then((result) => {
      if (result.value) {
        this.authService.logoutUser();
      }
    })


  }
  onClickErr() {
    if (this.setValue == null) {
      Swal.fire({
        title: 'Oops...',
        text: "Please Select Any Opal.",
        icon: 'info',
      })
    }
    else {
      Swal.fire({
        title: 'Error',
        text: "First you have to select body-brightness",
        icon: 'error',
      })
    }
  }
  startAgain() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to start again",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, i want!'
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['create-new-certificate']);
      }
    })

  }

  sendValue(data) {
    this.router.navigate(['/body-tone/photo-library', { title: data }]);
  }

}

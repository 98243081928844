import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../data.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-caret-weight',
  templateUrl: './caret-weight.component.html',
  styleUrls: ['./caret-weight.component.css']
})
export class CaretWeightComponent implements OnInit {

  constructor(private router: Router, private data: DataService, private ngxLoader: NgxUiLoaderService) {

  }

  ngOnInit() {

  }
  status: boolean = true;
  isReadOnly: boolean = true;
  selectedOption: any;
  flag = true;
  IsWholeSale: any;


  gen: any;
  cL: any;
  cU: any;
  sL: any;
  sU: any;

  name = null;

  caratValue = null;
  stoneValue = null;

  caratLowerValue: any = null;
  caratUpperValue: any;
  stoneLowerValue: any;
  stoneUpperValue: any;
  valAssFlag = true;
  showValueAsswsment = false;
  getFinalValue: any = [];
  caratWeight = '';
  markUp = '';
  // finalValue:any=[];
  updateRead() {
    this.isReadOnly = !this.isReadOnly;
  }
  getCaretWeight(crtwgt: NgForm, type) {
    this.ngxLoader.start();
    this.data.getCaretWeight(crtwgt.value.cw, crtwgt.value.mu, crtwgt.value.cc, crtwgt.value.ct, type).subscribe((data: any) => {
      this.caratWeight = crtwgt.value.cw;
      this.markUp = crtwgt.value.mu;
      if (data.body.StatusCode === 200) {

        this.ngxLoader.stop();
        this.status = false;
        // if(data.body.data.IsWholeSale==true){
        this.IsWholeSale = true;
        this.cL = Number(data.body.data.carat_value.lower);
        this.cU = Number(data.body.data.carat_value.upper);
        this.sL = Number(data.body.data.stone_value.lower);
        this.sU = Number(data.body.data.stone_value.upper);

        this.caratLowerValue = this.cL.toFixed(2);
        this.caratUpperValue = this.cU.toFixed(2);
        this.stoneLowerValue = this.sL.toFixed(2);
        this.stoneUpperValue = this.sU.toFixed(2);

        // }
        // else{
        //   this.IsWholeSale=false;
        //   this.wholeShaleStoneValue=null;
        //   this.wholeShaleCaratValue=null;
        //   this.retailStoneValue=data.body.data.StoneValue.toFixed(2);
        //   this.retailCaratValue=data.body.data.CaratValue.toFixed(2);
        //   }
        localStorage.setItem('cookie', data.cookie);
        this.valAssFlag = false;
      }
    });
  }


  valAsses() {
    this.data.valAsses().subscribe((data: any) => {
      if (data.body.StatusCode === 200) {
        this.getFinalValue = [];
        this.showValueAsswsment = true;
        var finalValue: any = [];
        finalValue = data.body.data;


        var str = finalValue[1]['Response'];
        var part = str.substring(
          str.lastIndexOf("(") + 1,
          str.lastIndexOf(")")
        );

        if (parseInt(part) <= 8) {
          finalValue[1]['Response']=finalValue[1]['Response'].replace('Grey', 'Black');
        }
        if (parseInt(part) >= 8 && parseInt(part) <= 13) {
          finalValue[1]['Response']=finalValue[1]['Response'].replace('Grey', 'Dark');
        }
        if (parseInt(part) >= 14 && parseInt(part) <= 21) {
          finalValue[1]['Response']=finalValue[1]['Response'].replace('Grey', 'Light');
        }


        //  this.getFinalValue=data.body.data;

        for (var v in finalValue) // for acts as a foreach  
        {
          this.getFinalValue[v] = (finalValue[v].Response);
        }

        if (!this.isReadOnly) {
          this.getFinalValue.push(this.caratWeight, this.markUp, this.caratValue, this.stoneValue);
        }
        else {
          this.getFinalValue.push(this.caratWeight, this.markUp, this.caratLowerValue, this.caratUpperValue, this.stoneLowerValue, this.stoneUpperValue);
        }
      }
    });
  }

  saveCertificate(svgCrt: NgForm) {

    this.data.saveCertificate(svgCrt.value.crtName, this.getFinalValue).subscribe((data: any) => {

      if (data.code == 200) {
        Swal.fire({
          icon: 'success',
          title: 'Certificate',
          text: data.message,
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/dashboard/user-certificate']);
          }
        })
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.message
        })
      }
    },
      error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.errors.name[0],
        })
      }
    )
  }

  showCaratWeight() {
    this.showValueAsswsment = false;
  }

  startAgain() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to start again",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, i want!'
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['create-new-certificate']);
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../data.service';
import { SharableService } from '../../sharable.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.css'],
  providers: [DatePipe]
})
export class AddCouponComponent implements OnInit {

  constructor(private datePipe: DatePipe, private data: DataService, private router: Router) { }
  time: any;
  errType:any;
  ngOnInit() {
    this.time = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
  }
  Toast = SharableService.Toast;


  doTheBack(){
    window.history.back();
  }

  onAddCoupon(cou: NgForm) {
    this.data.addCoupon(cou.value).subscribe((data:any)=>{
       if (data.code == 200) {
        this.Toast.fire({
          icon: 'success',
          title: data.message
        })
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
      }
      else {
          this.Toast.fire({
            icon: 'error',
            title: data.message
          })
        }
    },
    error => {
      if (error.error.errors['coupons.coupon_code']) {
        this.errType = error.error.errors['coupons.coupon_code'][0];
      }
      else if (error.error.errors['coupons.coupon_type']) {
        this.errType = error.error.errors['coupons.coupon_type'][0];
      }
      else if (error.error.errors['coupons.discount']) {
        this.errType = error.error.errors['coupons.discount'][0];
      }
      else if (error.error.errors['coupons.expiry_date']) {
        this.errType = error.error.errors['coupons.expiry_date'][0];
      }
      else if (error.error.errors['coupons.is_activated']) {
        this.errType = error.error.errors['coupons.is_activated'][0];
      }
      else if (error.error.errors['coupons.pricing_id']) {
        this.errType = error.error.errors['coupons.pricing_id'][0];
      }
      this.Toast.fire({
        icon: 'error',
        title:  this.errType
      })
    });
  }
}
